// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

//-----------------------|| APEXCHART ||-----------------------//

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

//-----------------------|| PERFECT SCROLLBAR ||-----------------------//

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

//-----------------------|| ANIMATION KEYFRAMES ||-----------------------//

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
    
    
}
.ayy {
	padding:5px 10px;
    font:inherit;
	display:inline-block;
    border-color: white;
	background:#ccc;
	font-size:20px;
    width:100%;
	color:rgb(255, 255, 255);
}
.btn-ayyan{
color:white;
background-color: #42378f;
background-image: linear-gradient(25deg, #42378f 0%, #f53844 74%);
margin-top: 17px;
}

.btn-ayyan2{
    color:white;
    background-color: #42378f;
    background-image: linear-gradient(25deg, #42378f 0%, #f53844 74%);
    // margin-top: 17px;
    }
    // background-image : linear-gradient(25deg,#f53844 16%, #42378f 84%);
    //  margin-top: 17px;
    //  width: 100px;
    //  border-radius: 20px;
    

    input[type="file"] {
        background-color: rgba(241, 241, 241, 0);
    }
    input::file-selector-button {
        font-weight: bold;
    margin-top: 17px; 
        padding: 0.5em;
        background: -webkit-linear-gradient(25deg,#f53844 0%, #42378f 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
    }
    input::-webkit-file-upload-button{
        border-left:black;
        border-top:black;
        border-bottom:black;
        margin-right: 10px;
    
    }

    .input-box {
        position: relative;
        width: 250px;
        margin-top: 15px;
      }
      
      .input-box input {
        position: relative;
        padding: 8px 5px;
        width: 100%;
        border: none;
        border-radius: 6px;
        outline: none;
        background: transparent;
        color: #fff;
        font-size: 1.25rem;
        letter-spacing: 0.05rem;
        z-index: 2;
        cursor: pointer;
      }
      
      .input-box span {
        position: absolute;
        left: 0;
        padding: 10px 0;
        pointer-events: none;
        font-size: 1rem;
        letter-spacing: 0.05rem;
        color: #333;
        transition: 0.5s;
      }
      
      .input-box input:valid ~ span,
      .input-box input:focus ~ span {
        font-size: 0.85rem;
        transform: translateY(-32px);
      }
      
      .input-box i {
        position: absolute;
        bottom: 0;
        top: 50;
        left: 0;
        width: 100%;
        height: 3px;
        border-radius: 1.5px;
        background: linear-gradient(45deg, #42378f, #f53844 74%);
        transition: 0.5s;
        z-index: 1;
        pointer-events: none;
      }
      
      .input-box input:valid ~ i,
      .input-box input:focus ~ i {
        height: 100%;
        border-radius: 6px;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
      }

.dropdown {
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    display: -webkit-inline-box;
    border-radius: 5px;
    margin: 4px;
    border: 1px solid #ced4da;
    background-color: #fff;
    background-clip: padding-box;
    color: #212529;
    outline: none;
    border-radius: 0.25rem;
    appearance: none;
    width: 100%;
   
   
   
   
   
    

    &:focus {
        /* border-bottom: 2px solid $theme-color--default; */
        border-bottom: 2px solid black;
        box-shadow: 0 0 2px 2px rgb(64, 63, 63);
        
    }

}